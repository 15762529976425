





















































import {
  DtoReportingForm,
  EmploymentCategory,
  FormRules,
  reqNewReporting
} from "@/api";
import ReportMenu from "../components/ReportMenu.vue";
import { Form, Message } from "element-ui";
import { Component, Mixins, Prop } from "vue-property-decorator";
import Reporting from "./Reporting.vue";
import ReportHead from "./ReportHead.vue";
import HasUser from "@/views/mixins/HasUser";

@Component({
  name: "register",
  components: {
    ReportMenu,
    Reporting,
    ReportHead
  },
  beforeRouteEnter(to, from, next) {
    if (!sessionStorage.getItem("token")) {
      Message.warning("请先登录");
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
    }
    next();
  }
})
export default class extends Mixins(HasUser) {
  @Prop({ type: String })
  harmId?: string;

  form: DtoReportingForm = {};

  employs = EmploymentCategory;

  get rules(): FormRules<DtoReportingForm> {
    return {
      personOrLegalPerson: [
        {
          required: true,
          trigger: "blur"
        }
      ],
      employmentCategory: [
        {
          required: true,
          message: `请选择从业类别`,
          trigger: "blur"
        }
      ]
    };
  }

  beforeSubmit() {
    if (this.harmId === "107") {
      return (this.$refs.form as Form).validate();
    } else {
      return null;
    }
  }

  async initNewReporting() {
    const { data } = await reqNewReporting();
    this.form = data;
    this.form.reportingWay = "REGISTRATION_REPORT";
    if (this.harmId === `107`) this.form.personOrLegalPerson = "PERSON";
    await this.$nextTick();
    (this.$refs.reportor as Reporting).initValid();
  }
  mounted() {
    this.initNewReporting();
  }
}
