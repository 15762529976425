import { reqUserInfo, VoUserInfo } from "@/api";
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";

@Component
export default class HasUser extends Vue {
  user: VoUserInfo = {
    address: "",
    city: "",
    county: "",
    email: "",
    id: "",
    phoneNumber: "",
    province: "",
    sex: "",
    trueName: "",
    userName: ""
  };
  @Mutation
  SET_USER_INFO!: (userInfo: string) => void;
  async initUserInfo() {
    try {
      const { data } = await reqUserInfo();
      this.user = data;
      this.SET_USER_INFO(JSON.stringify(data));
    } catch (error) {
      Message.error(`登录已经过期，请重新登录`);
      this.$router.replace({
        path: "/login",
        query: {
          redirect: this.$route.fullPath
        }
      });
    }
  }

  created() {
    this.initUserInfo();
  }
}
