






































import { Vue, Prop, Component } from "vue-property-decorator";
import { Mutation } from "vuex-class";
@Component({
  name: "report-head"
})
export default class ReportHead extends Vue {
  @Prop({ type: Object })
  info?: object;
  @Mutation
  SET_TOKEN!: (token: any) => void;
  @Mutation
  SET_USER_INFO!: (userInfo: any) => void;
  loginOut() {
    this.SET_TOKEN("");
    this.SET_USER_INFO("");
    this.$router.push("/");
  }
}
